import React, { useContext } from 'react'
import { E_invoice_context } from '../../../E_invoice_context'

const Custom_master_cms = () => {
  const { CreateViewCustomerData, setcustomviewshow } = useContext(E_invoice_context)
  // console.log(CreateViewCustomerData)
  return (
    <div style={{ border: '1px solid #dfdfdf', padding: '10px' }}>
      <div className='custom_legai_head'>
        <i onClick={() => setcustomviewshow(1)} className="fa-solid fa-circle-arrow-left"></i>
        <p> Customer Trade and Legal Name</p>
      </div>
      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>CMS Contract Number</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].cmscontractNumber}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Pan Number</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].panNumber}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Contract Activation Date</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].contractActivationDate}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row' >
        <div className='col-lg-4 col-12'>
          <h6>Payment Base Date</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].paymentBaseDate}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Contract End Date</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].contractEndDate}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Contract Closing Date</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].contractClosingDate}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Live Status</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].liveStatus}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>CMS Status</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].cmsstatus}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>BP Role</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].bprole}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Customer Name</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].customerName}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Dealer</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].dealer}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Rental Amount</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].rentalAmount}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Payment Mode</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].paymentMode}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Vehicle Type</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].vehicleType}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Financial Product</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].financialProduct}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Model</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].model}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Locality</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].locality}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Land Mark</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].landMark}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-6 col-12'>
          <h6>Bill To Address</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].address1}</p>
        </div>
        <div className='col-lg-6 col-12'>
          <h6>Ship To Address</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].address2}</p>
        </div>
      </div>


      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>District</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].district}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>City</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].city}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Postal Code</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].postalCode}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>State Name</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].stateName}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Country Name</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].countryName}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Cellular</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].cellular}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Phone</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].phone}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>FAX</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].fax}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Pager</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].pager}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>DayTime Phone</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].dayTimePhone}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>After Office Hours Phone</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].afterOfficeHoursPhone}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Fax Settlements</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].faxSettlements}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Email Add1</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].emailAdd1}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Email Add2</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].emailAdd2}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Email Add3</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].emailAdd3}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Email Add4</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].emailAdd4}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Mobile SMS</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].mobileSms}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>FI Manager</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].fimanager}</p>
        </div>
      </div>


      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Account Holder Name</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].accountHolderName}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Bank Account Number</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].bankAccountNumber}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Bank Name</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].bankName}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Bank Branch</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].bankBranch}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>MICR</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].micr}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>TDS Status</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].tdsstatus}</p>
        </div>
      </div>

      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Dishonor</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].dishonor}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Representation Charges</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].representationCharges}</p>
        </div>
        <div className='col-lg-4 col-12'>
          <h6>Over Due Amount</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].totalDueAmount}</p>
        </div>
      </div>


      <div className='row custom_legal_non_row'>
        <div className='col-lg-4 col-12'>
          <h6>Purchase Tax TIN Number</h6>
          <p>{CreateViewCustomerData && CreateViewCustomerData[0] && CreateViewCustomerData[0].purchaserTaxTinno}</p>
        </div>
      </div>


    </div>
  )
}

export default Custom_master_cms