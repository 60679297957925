
// import config from "../config.json"
import { Navigate } from 'react-router-dom'
import React from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';

var baseURL = process.env.React_App_baseURL;


const Logout = () => {
  sessionStorage.clear()
  Navigate('/');
}

<div>
  <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />

  <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
      <div className="modal-content mdl_cont">
        <div className="modal-header mdl_header">
          <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
        </div>
        <div className="modal-body mdl_bdy">
          Something Went Wrong.Please Login Again
        </div>
        <div className="modal-footer">
          <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>


let Currenttoken = "";

const decryptData = (name) => {
  
  const ciphertext = sessionStorage.getItem(name);
  if (ciphertext) {
    return ciphertext;
  }
  return "";
};


const PostServiceCallToken = (apiName, postData) => {
  
  Currenttoken = decryptData('Jwt_Token');
  console.log(baseURL + apiName)
  return axios
    .post(baseURL + apiName, postData, { headers: { "Authorization": `Bearer ${Currenttoken}` } })
    .then((response) => {
      if (response && response !== undefined && response !== null && response.data) {
        return response.data
      }
      else {
        return '';
      }
      // return response.data;
    }).catch(err => {
      console.log("err", err)
      const element = document.getElementById('go_to_logintime_all_inovice');
      element.click();
      // sessionStorage.clear();
      if (err.response.data) {
        if (err.response.data === "Invalid Token") {
          // sessionStorage.clear();
        }
      }
    });
};

const PostServiceCall = (apiName, postData) => {
 
  Currenttoken = decryptData('Jwt_Token');
  return axios
    .post(baseURL + apiName, postData, { headers: { "Authorization": `Bearer ${Currenttoken}` } })
    .then((response) => {
      if (response && response !== undefined && response !== null && response.data) {
        return response.data
      }
      else {
        return '';
      }

    }).catch(err => {
      const element = document.getElementById('go_to_logintime_all_inovice');
      element.click();
      let apierror = err.message
      if (err.response.data.errors) {
        apierror = err.response.data.errors[0] && err.response.data.errors[0].msg
      } else if (err.response.data.msg) {
        apierror = err.response.data.msg
      }
      return apierror
    });
};

const GetServiceCall = (apiName) => {
 
  Currenttoken = decryptData('Jwt_Token');
  return axios
    .get(baseURL + apiName, { headers: { "Authorization": `Bearer ${Currenttoken}` } })
    .then((response) => {
      if (response && response !== undefined && response !== null && response.data) {
        return response.data
      }
      else {
        return '';
      }

    }).catch(err => {
      const element = document.getElementById('go_to_logintime_all_inovice');
      element.click();
      let apierror = err.message
      if (err.response.data.errors) {
        apierror = err.response.data.errors[0] && err.response.data.errors[0].msg
      } else if (err.response.data.msg) {
        apierror = err.response.data.msg
      }
      return apierror
    });
};

const Deleteserivecall = (apiName) => {
  
  Currenttoken = decryptData('Jwt_Token');
  console.log(baseURL + apiName)
  return axios
    .delete(baseURL + apiName, { headers: { "Authorization": `Bearer ${Currenttoken}` } })
    .then((response) => {
      return response.data;
    }).catch(err => {
      const element = document.getElementById('go_to_logintime_all_inovice');
      element.click();
      console.log("err", err)
      // sessionStorage.clear();
      if (err.response.data) {
        if (err.response.data === "Invalid Token") {
          // sessionStorage.clear();
        }
      }
    });
};

const AuthService = {
  PostServiceCallToken,
  PostServiceCall,
  GetServiceCall,
  Deleteserivecall
};

export default AuthService;