import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Invoice_create_config from './Invoice_create_config'
import Pagination from '../../Pagination'
import Invoice_type_edit from './Invoice_type_edit'
import { useNavigate } from 'react-router-dom'

const Invoice_type_config = () => {
    const { sessionStartTime, sessionEndTime, invoicetypeshow, setinvoicetypeshow, invoicetypedata, setinvoicetypesearch,invoicetypesearch, CurrentUser, Getinvoicetypedata, invoicetypepageNumbers, setinvoiceinput, setinvoicecofigerror,
        invoiceTypehandlePageChange, invoicetypePaginations, invoicecreate, invoicetyperesponse, invoiceviewdropdown, setinvoicedropdownvalue, SetinvoicetypePaginations,
        handleinvoicetypeedit, Getinvoicetypedropdown, handleinvoicetypeDropdownChange, invoicedropdownvalue, invoicetypeenbledvalue,
        invoicetypeeditview, setinvoicetypeeditview, handleClick, Ascending, Getinseretstatecodeconfig, decryptData } = useContext(E_invoice_context)

    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime_state').click();
        }
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }

    useEffect(() => {
        if (CurrentUser) {
            Getinvoicetypedata();
            Getinvoicetypedropdown();
            Getinseretstatecodeconfig();
        }
    }, [invoicetypePaginations, invoicedropdownvalue, invoicetypeenbledvalue, Ascending])

    const SearchBox = () => {
        SetinvoicetypePaginations({ ...invoicetypePaginations, Current_page: invoicetypePaginations.Current_page = 1 });
        Getinvoicetypedata();
    }

    const Invoice_icon_bk = () => {
        setinvoicetypeshow(1)
        setinvoiceinput(invoicecreate)
        setinvoicecofigerror(true)
    }
    const invoicetypecancel = () => {
        setinvoicetypeshow(1)
        setinvoiceinput(invoicecreate)
        setinvoicetypeeditview({ ...invoicetypeeditview, Edit: true })
        setinvoicecofigerror(true)
    }

    const invoiceentersearch = (e) => {
        if (e.keyCode === 13) {
            SearchBox();
        }
    }
    const search_invoice_type = (e) => {
        setinvoicedropdownvalue(e.target.value)
        SetinvoicetypePaginations({ ...invoicetypePaginations, Current_page: invoicetypePaginations.Current_page = 1 });
    }
    const invoice_en_dis = (e) => {
        handleinvoicetypeDropdownChange(e);
        SetinvoicetypePaginations({ ...invoicetypePaginations, Current_page: invoicetypePaginations.Current_page = 1 });
    }
    return (
        <div>
            <div className="h4_btm_bdr"><h4>Invoice Type</h4></div>


            {
                invoicetypeshow === 1 &&
                <div className='total_outfit_fr_tab'>
                    <div className="create_btn_top">
                        <div>
                            <button onClick={() => {setinvoicetypeshow(2);  setinvoiceinput(invoicecreate)}} className="grn_btn">Create Invoice Type</button>
                        </div>
                        <div className='create_right_aligh'>
                            <select className="select_inp_filed" value={invoicetypeenbledvalue} onChange={invoice_en_dis} aria-label="Default select example">
                                <option value=''>All</option>
                                <option value="1">Enabled</option>
                                <option value="2">Disabled</option>
                            </select>
                            <select value={invoicedropdownvalue} className="select_inp_filed" onChange={search_invoice_type} aria-label="Default select example">
                                <option selected value=''>Supplier Name</option>
                                {
                                    invoiceviewdropdown && invoiceviewdropdown.map((invoicedrop, uq) => (
                                        <option key={uq} value={invoicedrop.supplierIdentifier}>
                                            {invoicedrop.supplierTradeName}
                                        </option>
                                    ))
                                }
                            </select>
                            <input onKeyDown={invoiceentersearch} value={invoicetypesearch} onChange={(e) => setinvoicetypesearch(e.target.value)} className="search_inp_filed" type="text" name="" id="" placeholder="Search"></input>
                            <i onClick={SearchBox} className="fa-solid fa-magnifying-glass search_icon"></i>
                        </div>
                    </div>
                    <table className='table_c__M'>
                        <thead>
                            <tr>
                                <th><span style={{ opacity: '0' }}>Hidden Text</span></th>
                                <th onClick={() => handleClick('InvoiceTypeName')}>Invoice Type Name</th>
                                <th onClick={() => handleClick('InvoiceTypeSerialCode')}>Invoice Type Serial Code</th>
                                <th onClick={() => handleClick('ActiveFlag')}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoicetypedata && invoicetypedata.map((invoice, invoiceiq) => (
                                    <tr key={invoiceiq}>
                                        <td><button onClick={() => handleinvoicetypeedit(invoice.invoiceTypeConfigurationIdentifier)} className='c_m_btn'>View</button></td>
                                        <td >{invoice.invoiceTypeName}</td>
                                        <td >{invoice.invoiceTypeSerialCode}</td>
                                        <td >{invoice.activeFlag === true ? ("Enabled") : ('Disabled')}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <div>
                            <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{invoicetyperesponse}</span></p>
                        </div>
                        <div>
                            <Pagination
                                pageNumbers={invoicetypepageNumbers}
                                Paginations={invoicetypePaginations}
                                handlePageChange={invoiceTypehandlePageChange}
                                LastPage={invoicetyperesponse && Math.ceil(invoicetyperesponse / 10)}
                            />
                        </div>
                    </div>

                </div>
            }

            {
                invoicetypeshow === 2 &&
                <Invoice_create_config
                    Invoice_icon_bk={Invoice_icon_bk}
                />
            }
            {
                invoicetypeshow === 3 &&
                <Invoice_type_edit
                    Invoice_icon_bk={Invoice_icon_bk}
                    setinvoicetypeeditview={setinvoicetypeeditview}
                    invoicetypeeditview={invoicetypeeditview}
                    invoicetypecancel={invoicetypecancel}
                />
            }


            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
            <input type='button' hidden id='go_to_logintime_state' data-bs-toggle="modal" data-bs-target="#go_to_login_state" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_state" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Invoice_type_config